.mobile-body {
    background: #FAF6F4;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #D1AB42 !important;
}

.AttendingStyle {
    max-width: 100% !important
}

input[type=email],
input[type=website],
input[type=number],
input[type=time] {
    outline: none !important;
    border: none !important;
    padding: 8px;
}

.settingStyle {
    padding: 30px;
}


.imgChange {
    position: relative;
    border-radius: 20px;
}

.imgStyle {
    border-radius: 10px;

}

.imgChangeBtn {
    position: absolute !important;
    top: 0px;
    right: 5px;
}

.imageStyle {
    border-radius: 10px !important;
}

@media only screen and (max-width: 1070px) {
    .settingStyle {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 600px) {
    .mobile-body {
        background: #BBF8F7;
    }

    .settingStyle {
        justify-content: center !important;
    }

    .imageUpload {
        flex-direction: row !important;
        gap: 40px;
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .mobile-responsive-width {
        width: 100% !important;
    }

    .settingStyle {
        padding: 0px;
    }

    .cardDiv {
        width: 100% !important
    }

    .AttendingStyle {
        width: 100% !important
    }

    .church-album {
        width: 100% !important;
    }

}