.select-styles {
    border-style: none !important;
}

.img-responsive {
    border-radius: 6%;
}

.speakerImage {
    border-radius: 6%;
}

.css-1u9des2-indicatorSeparator {
    width: none !important;
}

.iconsStyle {
    color: #3F292B;
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
}

.imagestyle {
    border-radius: 50%;
}

.iconStyle2 {
    color: #D1AB42;
    margin-right: 5px;
    margin-bottom: -3px;

    font-size: 19px;
    display: inline;
    align-items: center;
}

.select-styles {
    font-size: 13px;
    width: 150px
}

.widthth {
    width: 40px
}

.eventsContent {
    margin: 10px 10px 10px 40px !important;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media only screen and (max-width: 1390px) {
    .thirdCol {
        flex-direction: row !important;
        gap: 20px;
    }
}

@media only screen and (max-width: 1162px) {
    .eventsContent {
        flex-wrap: wrap;
        width: 100% !important;
    }

    .eventsContent {
        margin: 10px 10px 10px 10px !important;
    }
}


@media only screen and (max-width: 992px) {
    .flexMobile {
        flex-wrap: wrap;

    }
}


@media only screen and (max-width: 600px) {
    .select-mobile-width {
        width: 200px !important;
    }

    .heading-album {
        font-size: 1.4rem !important;
        text-align: center;
    }

    .img-responsive {
        width: 100% !important;

    }

    .flexMobile {
        flex-wrap: wrap;
    }

    .eventsContent {
        width: 100% !important;
        margin: 25px 0 5px 0 !important;
    }

    .register-btn-mobile {
        width: 100% !important;
    }

    .mobile-responsive-register-btn {
        width: 80%;
    }

    .field-align-responsive {
        justify-content: space-between !important;
        width: 100%;
    }

    .field-align-responsive {
        padding: 8px 0;
        border-bottom: 1px solid #dbd9d9;
    }
}

@media only screen and (max-width: 428px) {
    .mobile-responsive-register-btn {
        width: 100%;
    }
}