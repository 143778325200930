.css-11bsbwi-MuiButtonBase-root-MuiButton-root:hover {
    border: none !important;
    background-color: #240C00 !important;
}

.css-1llecj4-MuiButtonBase-root-MuiButton-root:hover {
    border: none !important;
    background-color: #D1AB42 !important;
}

.css-1a7pk4q-MuiButtonBase-root-MuiButton-root:hover {
    border: none !important;
    background-color: #240C00 !important;

}

.leftPart {
    width: 60%;
}

.fields {
    width: 80%;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20vh 0;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7986cb;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}


@media only screen and (max-width: 950px) {
    .wrap {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 900px) {
    .leftPart {
        width: 100%;
    }

    .fields {
        width: 100%
    }


}

@media only screen and (max-width: 820px) {
    .space {
        margin-right: 0 !important;
        margin-bottom: 15px;
    }

}

.otpStyle {
    height: 2.5em !important;
    width: 2.5em !important;
    color: black !important;
    /* background: transparent !important; */
}

.textStyleStripe {
    border: 1px solid lightgray !important;
    /* width: 250px !important; */
    border-radius: 5px !important;
    padding: 9px !important;
}

.btnStyle {
    color: #ffffff;
    background: #3F292B;
    font-family: Kumbh Sans;
    font-size: 10px;
    padding: 12px 35px;
    margin-top: 12px;
    border-radius: 5px;
    border: 1px solid #3F292B;
}

.btnStyle:hover {
    border: 1px solid #3F292B;
    color: #3F292B;
    background: transparent;
}

@media only screen and (max-width: 600px) {
    .mobile-Body {
        margin: 0 30px !important;
    }

    .img-mobile {
        width: 100% !important;
    }

    .cardDetailsHeading {
        font-size: 20px !important;
        /* text-align: center; */
    }

    .btn-mobile {
        margin-top: -20px !important;
    }

    .card-width-mobile {
        width: 100% !important;
        height: 150px !important;
    }

    .btn-register-mobile {
        margin: 20px auto !important;
    }

    .register-card-data {
        align-items: center !important;
        /* bottom: 20px */
    }

}

@media only screen and (max-width: 406px) {
    .package {
        justify-content: center !important;
    }

    .card-width-mobile {
        width: 100% !important;
        height: 200px !important;
    }

}

@media only screen and (max-width: 390px) {
    .card-Text {
        display: none;
    }
}