.preStaff {
    display: block;
    top: -30px !important;
    left: 46.5% !important;
}

.nextStaff {
    display: block;
    position: absolute;
    top: -30px !important;
    right: 46.5% !important;
}

.slick-prev:before {
    border: 0.2px solid grey !important;
    border-radius: 50%;
    background-image: url('../../assets/icons/LeftArrow.png');
    background-size: 1.8rem;
    background-repeat: no-repeat;
    background-position: center;
}

.slick-next:before {
    border: 0.2px solid grey !important;
    border-radius: 50%;
    background-image: url('../../assets/icons/RightArrow.png');
    background-size: 1.8rem;
    background-repeat: no-repeat;
    background-position: center;
}

.WhoWeAreStyle {
    margin: 60px 0 !important;
}

.eventss {
    background-image: url("../../assets/images/homePagePopular.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 42px;
}


@media only screen and (max-width: 992px) {
    .search-width {
        width: 100%
    }

    .preStaff {
        left: 40% !important;
    }

    .WhoWeAreStyle {
        margin: 40px 0 !important;
    }

}

@media only screen and (max-width: 600px) {
    .search-width {
        width: 100%
    }

    .WhoWeAreStyle {
        margin: 30px 0 !important;
    }

    .whatWeDo {
        display: flex;
        flex-wrap: wrap !important;
    }

    .middleParagraph {
        margin: 20px 0 !important;
    }
}


@media only screen and (max-width: 428px) {
    .preStaff {
        left: 30% !important;
    }
    .nextStaff {
        right: 30% !important;
    }

    .WhoWeAreStyle {
        margin: 30px 0 !important;
    }

}