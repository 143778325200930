body {
  margin: 0;
  font-family: 'Kumbh Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  overflow: auto;
}

html {
  min-height: 100%;
  overflow: none;
}

a {
  text-decoration: none;
  position: relative;
  color: #000000;
}


/* a::before {
  background: hsl(45 100% 70%);
  content: "";
  inset: 0;
  position: absolute;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s ease-in-out;
  z-index: -1;
}

a:hover::before {
  transform: scaleX(1);
  transform-origin: left;
} */


.css-1nisqmr-MuiInputBase-root-MuiInput-root:before,
.css-1yj78uf-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
}

.css-1rfj7ft-MuiPaper-root-MuiCard-root,
.css-qltblr-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
}

.mobile-responsive-body {
  padding: 50px 90px !important;
  width: 100%;
  box-sizing: border-box;
}

.mobile-responsive-body-color {
  padding: 30px 90px !important;
  width: 100%;
  box-sizing: border-box;
  background-color: #FAF6F4;
}

.profile-body {
  /* padding: 50px !important; */
  width: 100%;
  box-sizing: border-box;
}

.admin-responsive-body {
  padding: 50px 50px !important;
  width: 100%;
  box-sizing: border-box;
}

.MuiSelect-outlined {
  outline: none !important;

}

@media only screen and (max-width: 600px) {

  .mobile-responsive-body {
    padding: 50px 30px !important;
  }

  .profile-body {
    padding: 50px 20px !important;
  }

  .mobile-responsive-body-color {
    padding: 50px 40px !important;
    width: 100%;
    box-sizing: border-box;
    background-color: #FAF6F4;
  }

  .admin-responsive-body {
    padding: 50px 20px !important;
    box-sizing: border-box;
  }

}

.css-13cymwt-control {
  border-style: none !important;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

.css-1xc3v61-indicatorContainer {
  color: black !important
}

.css-t3ipsp-control {
  box-shadow: none !important;
  border-color: transparent !important;
}

.css-t3ipsp-control:hover {
  border-color: #D1AB42 !important;
}

.css-xz2e1l-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px 10px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px 10px !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: 200px !important;
}

.mapStyle {
  height: 100%;
  width: 100%;
}