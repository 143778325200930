.preBackBlog {
    display: block;
    left: 90% !important;
    top: -35px !important;
}

.nextBackBlog {
    display: block;
    position: absolute;
    right: 35px !important;
    top: -35px !important;
}

.slick-prev:before {
    border: 0.2px solid grey !important;
    border-radius: 50%;
    background-image: url('../../assets/icons/LeftArrow.png');
    background-size: 1.8rem;

    background-repeat: no-repeat;
    background-position: center;
}


.slick-next:before {
    border: 0.2px solid grey !important;
    border-radius: 50%;
    background-image: url('../../assets/icons/RightArrow.png');
    background-size: 1.8rem;
    background-repeat: no-repeat;
    background-position: center;
}

.breakLine {
    display: none;
}

@media only screen and (max-width: 992px) {
    .search-width {
        width: 100%
    }

    .preBackBlog {
        left: 80% !important;
    }

}

@media only screen and (max-width: 600px) {
    .search-width {
        width: 100%
    }

    .preBackBlog {
        left: 70% !important;
    }


}

@media only screen and (max-width: 360px) {
    .preBackBlog {
        left: 60% !important;
    }

    /* .bigHeadingResponsive{
        margin-bottom: 60px;
    } */

    .breakLine {
        display: block;
    }

}