.mobile-body {
    background: #FAF6F4;
}

.AttendingStyle {
    max-width: 100% !important
}

.cardDiv {
    max-width: 300px;
    height: 160px;
}


.css-z78ipl-MuiPaper-root-MuiCard-root {
    box-shadow: none !important;
}

.imgChange1 {
    position: relative;
}

.imgChangeBtn1 {
    position: absolute !important;
    top: 4px;
    right: 6px;
}

.imgChangeBtn {
    position: absolute !important;
    top: 4px;
    right: 25px;
}

.imageStyle {
    border-radius: 8%;
}


@media only screen and (max-width: 600px) {
    .mobile-body {
        background: #BBF8F7;
    }

    .cardDiv {
        width: 100%;
    }

    .AttendingStyle {
        width: 100% !important
    }

}

@media only screen and (max-width: 450px) {
    .cardDiv {
        max-width: 260px;
    }
}