
@media only screen and (max-width: 600px) {
    .socialIcon {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .socialIconCss {
        margin: 0 15px !important;
    }
}

@media only screen and (max-width: 405px) {
    .socialIconCss {
        margin: 5px 15px !important;
    }
}