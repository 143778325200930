.imgChange2 {
    position: relative;
}

.imgChangeBtn2 {
    position: absolute !important;
    top: 4px;
    right: 6px;
}


@media only screen and (max-width: 992px) {
    .dashboard-table {
        flex-wrap: wrap !important;
    }
}

@media only screen and (max-width: 600px) {
    .mobile-body {
        background: #BBF8F7;
    }
}