.textStyle {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 300px;
    height: 40px;
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;

    position: absolute;
    left: 22%;
    top: 10px;
    margin-left: -120px;
}

.confirmBtnStyle {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 170px;
    background: DodgerBlue;
    color: white;
    height: 40px;
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;

    position: absolute;
    left: 22%;
    top: 20px;
    margin-left: 200px;
    z-index: 9999;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .textStyle {
        width: 170px;
        left: 130px;
        top: 60px;
    }

    .confirmBtnStyle {
        width: 100px;
        left: -5px;
        top: 70.5px;
    }
}