.css-hyum1k-MuiToolbar-root {
    justify-content: end !important;
}

/* .MuiButton-root span:hover{
    border: none;
    outline: none;
} */

.css-9anmeu-MuiButtonBase-root-MuiButton-root:hover {
    border: none !important;
    background-color: #240C00 !important;
}

.css-102njdr-MuiButtonBase-root-MuiButton-root:hover {
    border: none !important;
    background-color: #D1AB42 !important;
}

.css-wmflbe-MuiPaper-root-MuiAppBar-root,
.css-145tl72-MuiPaper-root-MuiAppBar-root {
    box-shadow: none !important;
}

.css-axsifv-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #240C00 !important;

}

.css-1sa5jaq-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    margin-top: 46px !important;
    margin-right: 46px !important
}