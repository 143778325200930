.events {
    background-image: url("../../assets/images/homePagePopular.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 42px;
}

.attendance-btn-Width {
    margin: 5px !important;
}

.crossPicture {
    max-width: 550px;
}


@media only screen and (max-width: 600px) {

    /* .quickDivMobile1 {
        display: flex !important;
        justify-content: center !important;

    } */

    .quickDivMobile {
        width: 100% !important;
        justify-content: center !important;
        font-size: 20px !important;
    }

    .homepage-align {
        justify-content: center !important;
        height: 100% !important;
        flex-wrap: wrap !important;
    }

    .crossPicture {
        max-width: 280px;
    }

    .attendance-btn-Width {
        width: 100% !important;
        /* margin: 10px !important; */
    }

    .post-btn-Width {
        width: 95% !important;
        margin: 20px !important;
    }

    .bigHead-mobile {
        font-size: 26px !important;
        text-align: center;
    }

    .shortHead-mobile {
        text-align: center;
    }
}